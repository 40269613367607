import React from 'react'
import Painel from '../components/Senhas/Painel'

const Senhas = () => {
  return (
    <div>
      <Painel />
    </div>
  )
}

export default Senhas